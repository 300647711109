import {
  LinearProgress,
  useMediaQuery,
  Typography,
  useTheme,
  Tooltip,
  Divider,
  Button,
  Paper,
  alpha,
  Stack,
  Box,
} from "@mui/material";
import { BsFillCalendar2RangeFill, BsFillTagsFill } from "react-icons/bs";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { MdAutoAwesome, MdWatchLater } from "react-icons/md";
import React, { useEffect, useRef, useState } from "react";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import { HiMiniDocumentDuplicate } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RiBankCardFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { TbListSearch } from "react-icons/tb";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import format from "date-fns/format";

import {
  setTransactionsOverlayStatus,
  setPopupStatus4,
  setPopupStatus5,
} from "../../../store/slices/datasets";
import {
  getTransactionByParams,
  getIntegrationCard,
  formatAmount,
  remToPx,
} from "../../../Helper/data";
import {
  setStageLoadingText,
  setRefreshBalance,
  setRefreshData,
} from "../../../store/slices/appmain";
import CombinedDataGridFooter from "./Components/CombinedDataGridFooter";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import RulesCommonView from "../../Settings/Rules/RulesCommonView";
import ExportDialogView from "./Components/ExportDialogView";
import ScenarioChip from "../../../components/ScenarioChip";
import CategoryChip from "../../../components/CategoryChip";
import ActionButton from "../../../components/ActionButton";
import { Color, Constant, Fonts } from "../../../Helper";
import StateChip from "../../../components/StateChip";
import ThemeTabs from "../../../components/ThemeTabs";
import ReconcileListView from "./ReconcileListView";
import EndPoints from "../../../APICall/EndPoints";
import initialData from "./../../../Helper/data";
import ListHeader from "./Components/ListHeader";
import useWidth from "../../../hooks/useWidth";
import Icon from "../../../components/Icon";
import { queryClient } from "../../../App";
import BulkListView from "./BulkListView";
import APICall from "../../../APICall";
import store from "../../../store";

const useStyles = makeStyles({
  root: {
    backgroundColor: Color.appThemeBg,
    height: "100%",
    paddingTop: "1rem",
    width: "100%",
    overflow: "hidden",
    [`& .last-header-cell`]: {
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
    },
  },
  table: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "0.9375rem",
      fontWeight: 600,
    },

    "& .MuiDataGrid-row": {
      cursor: "pointer",
    },
  },
  lastColumnHeader: {
    "& .MuiDataGrid-colCell:last-child": {
      borderRight: "none",
    },
  },
});
const tabOptions = [
  {
    id: 1,
    key: "bulk",
    value: "bulkUnCatArray",
    iconPosition: "start",
    label: "Bulk categorization",
    icon: (
      <Icon
        icon={<BsFillTagsFill />}
        fontSize={"1.1rem"}
        style={{
          mr: "0.3rem",
        }}
      />
    ),
    className: "",
  },
  // {
  //   id: 2,
  //   key: "suggestion",
  //   value: "suggestionArray",
  //   iconPosition: "start",
  //   label: "Suggestions",
  //   icon: (
  //     <AutoFixHighIcon color={Color.tailwind.slate[600]} />
  //   ),
  //   className: "",
  // },
  {
    id: 3,
    key: "bank",
    value: "bankArray",
    iconPosition: "start",
    label: "Bank",
    icon: (
      <Icon
        icon={<RiBankCardFill />}
        fontSize={"1.2rem"}
        style={{
          mr: "0.3rem",
        }}
      />
    ),
    className: "",
  },
  {
    id: 4,
    key: "open",
    value: "openArray",
    iconPosition: "start",
    label: "Open Positions",
    icon: (
      <Icon
        icon={<HiMiniDocumentDuplicate />}
        fontSize={"1.2rem"}
        style={{
          mr: "0.3rem",
        }}
      />
    ),
    className: "",
  },
  {
    id: 5,
    key: "budget",
    value: "plannedArray",
    iconPosition: "start",
    label: "Budgets",
    icon: (
      <Icon
        icon={<BsFillCalendar2RangeFill />}
        fontSize={"0.9rem"}
        style={{
          mr: "0.3rem",
        }}
      />
    ),
    className: "",
  },
  {
    id: 6,
    key: "reconcile",
    value: "reconcileArray",
    iconPosition: "start",
    label: "Reconcile",
    icon: (
      <CompareArrowsIcon
        sx={{
          fontSize: "1.2rem",
        }}
      />
    ),
    className: "",
  },
  {
    id: 7,
    key: "late",
    value: "lateArray",
    iconPosition: "start",
    label: "Late",
    icon: (
      <Icon
        icon={<MdWatchLater />}
        fontSize={"1.2rem"}
        style={{
          mr: "0.3rem",
        }}
      />
    ),
    className: "",
  },
  {
    id: 8,
    key: "all",
    value: "allArray",
    iconPosition: "start",
    label: "All",
    icon: (
      <Icon
        icon={<TbListSearch />}
        fontSize={"1.2rem"}
        style={{
          mr: "0.3rem",
        }}
      />
    ),
    className: "",
  },

  // { id: 2, key: "categorized", value: "catArray" },
  // { id: 3, key: "uncategorized", value: "unCatArray" },
  // { id: 7, key: "issues", value: "issueArray" },
];
const Transactions = () => {
  const currentWidth = useWidth();
  const rulesRef = useRef(null);
  const timer = useRef(0);
  const prevent = useRef(false);
  const delay = useRef(200);

  const updateType = useRef("single");
  const itemRow = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const s2133 = useMediaQuery("(min-width:2133px)");
  const s2400 = useMediaQuery("(min-width:2400px)");
  let HEIGHT = 2.5;
  let spacing = 110;
  const _width = document.body.clientWidth;
  const width = (_width * 85) / 100;
  if (s2133) {
    HEIGHT = 3;
  }
  if (s2400) {
    HEIGHT = 5;
  }

  //redux
  const costunitsById = useSelector((state) => state.globalSlice.costunitsById);
  const vat = useSelector((state) => state.globalSlice.vat);
  const state = useSelector((state) => state.globalSlice.state);
  const scenario = useSelector((state) => state.globalSlice.scenario);
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
  const refreshData = useSelector((state) => state.appSlice?.refreshData);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const costunits = useSelector((state) => state.globalSlice?.costunits);
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  // const contacts = useSelector((state) => state.globalSlice?.contacts);
  // const contactsById = useSelector((state) => state.globalSlice.contactsById);

  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const profile = useSelector((state) => state.settingsSlice?.profile);

  //state
  const [options, setOptions] = useState({
    allState: [],
    openState: [],
    plannedState: [],
    bookedState: [],
    scenario: [],
    vat: [],
    cost_unit: [],
  });
  const [rows, setRows] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [tab, setTab] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [allowUpdate, setAllowUpdate] = useState(true);
  const [updateHelper, setUpdateHelper] = useState(true);
  const [page, setPage] = useState(0);
  const [rowCountState, setRowCountState] = useState(0);
  const [bankName, setBankName] = useState({});
  const [order_by, setOrder_by] = useState("-due_date");
  const [pageSize, setPageSize] = useState(20);

  const isPlanned = tab === "plannedArray";
  const hideSource = tab !== "allArray";
  const hideState =
    tab === "bulkUnCatArray" ||
    tab === "reconcileArray" ||
    tab === "lateArray" ||
    tab === "plannedArray" ||
    tab === "suggestionArray";
  const hideScenario =
    tab === "bankArray" || tab === "bulkUnCatArray" || tab === "reconcileArray";

  const hideCategory =
    tab === "suggestionArray" ||
    tab === "bulkUnCatArray" ||
    tab === "reconcileArray" ||
    tab === "unCatArray";
  const hideCostUnit =
    tab === "reconcileArray" ||
    tab === "bulkUnCatArray" ||
    tab === "suggestionArray";
  //data
  const sameColumn = [
    {
      field: "due_date",
      headerName: t("Date"),
      flex: 0.65,
      editable: true,
      type: "date",
      cellClassName: "date-color",
      valueGetter: ({ value }) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: ({ value }) => {
        return value
          ? format(
              new Date(value),
              profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy"
            )
          : null;
      },
    },
    // {
    //   field: "invoice_date",
    //   headerName: t("Invoice Date"),
    //   flex: 1,
    //   editable: true,
    //   type: "date",
    //   cellClassName: "date-color",
    //   valueGetter: ({ value }) => {
    //     return value ? new Date(value) : null;
    //   },
    //   valueFormatter: ({ value }) => {
    //     return value
    //       ? format(
    //           new Date(value),
    //           profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy"
    //         )
    //       : null;
    //   },
    // },
    {
      field: "title",
      headerName: t("Title"),
      flex: 1.7,
      editable: true,
    },
    {
      field: "note",
      headerName: t("Note"),
      flex: 1.7,
      editable: true,
      sortable: false,
    },
    // {
    //   field: "contact",
    //   headerName: t("Contact"),
    //   editable: true,
    //   sortable: false,
    //   type: "singleSelect",
    //   valueOptions: contactData.option,
    //   renderCell: (params) => {
    //     return (
    //       <Tooltip
    //         title={
    //           params?.value && contactsById[params?.value]
    //             ? contactsById[params?.value][0]?.name
    //             : ""
    //         }
    //       >
    //         <Typography
    //           variant="h6"
    //           component={"h6"}
    //           sx={{
    //             fontWeight: 400,
    //             fontFamily: Fonts.Text,
    //             fontSize: "0.875rem",
    //             overflow: "hidden",
    //             textOverflow: "ellipsis",
    //             whiteSpace: "nowrap",
    //           }}
    //         >
    //           {params?.value && contactsById[params?.value]
    //             ? contactsById[params?.value][0]?.name
    //             : ""}
    //         </Typography>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "data_source",
      headerName: tab === "bankArray" ? t("Bank") : t("Data_Source"),
      flex: 0.7,
      editable: false,
      renderCell: (params) => {
        const dataSourceById = store?.getState()?.globalSlice?.dataSourceById;
        const ds = dataSourceById?.[params?.row?.data_source]?.[0];
        let card = getIntegrationCard({ ds });
        if (ds?.internal_dataset) {
          card = { title: "Internal data source" };
        }
        if (params.row?.source === 2) {
          card = { title: bankName?.[params.row?.account] };
        }

        return (
          <Tooltip title={t(card?.title)}>
            <p
              style={{
                fontFamily: Fonts.Text,
                fontSize: "0.875rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {t(card?.title)}
            </p>
          </Tooltip>
        );
      },
    },

    {
      field: "cost_unit",
      headerName: t("Cost_Unit"),
      flex: 0.75,
      editable: true,
      sortable: false,
      type: "singleSelect",
      valueOptions: options.cost_unit,
      renderCell: (params) => {
        return (
          <Tooltip
            title={
              params?.value && costunitsById[params?.value]
                ? costunitsById[params?.value][0]?.title
                : ""
            }
          >
            <Typography
              variant="h6"
              component={"h6"}
              sx={{
                fontWeight: 400,
                fontFamily: Fonts.Text,
                fontSize: "0.875rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params?.value && costunitsById[params?.value]
                ? costunitsById[params?.value][0]?.title
                : ""}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: "gross_value",
      headerName: t("Value"),
      flex: 0.75,
      editable: true,
      type: "float",
      renderCell: (params) => {
        return (
          <Tooltip
            title={formatAmount({
              amount: params?.value,
            })}
          >
            <p
              key={params?.row?.uuid}
              style={{
                color:
                  parseFloat(params.row?.gross_value) >= 0
                    ? Color.green
                    : Color.red,
                fontFamily: Fonts.Text,
                fontSize: "0.875rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {formatAmount({
                amount: params?.value,
              })}
            </p>
          </Tooltip>
        );
      },
    },
    // {
    //   field: "tax",
    //   headerName: t("label_tax"),
    //   flex: 1,
    //   editable: true,
    //   sortable: false,
    //   type: "singleSelect",
    //   valueOptions: options.vat,
    //   renderCell: (params) => {
    //     let data = options.vat?.find((o1) => o1.value === params?.row?.tax);

    //     return (
    //       <p
    //         key={params?.row?.uuid}
    //         style={{
    //           fontWeight: 400,
    //           fontFamily: Fonts.Text,
    //           fontSize: "0.875rem",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           whiteSpace: "nowrap",
    //         }}
    //       >
    //         {data
    //           ? `${data?.label}`
    //           : params?.row?.source === 4
    //           ? `${(
    //               ((params?.row?.gross_value -
    //                 params?.row?.calculated_net_value) *
    //                 100) /
    //                 params?.row?.gross_value || 0
    //             ).toFixed(1)}%`
    //           : ""}
    //       </p>
    //     );
    //   },
    // },
    {
      field: "state",
      headerName: t("State"),
      flex: 0.8,
      editable: true,
      sortable: false,
      type: "singleSelect",
      valueOptions: (params) => {
        if (params?.row?.source === 1) {
          return options.allState;
        } else if (params?.row?.state === "Planned") {
          return options.plannedState;
        } else if (params?.row?.source === 2) {
          return options.bookedState;
        } else {
          return options.openState;
        }
      },
      renderCell: (params) => {
        return (
          <StateChip
            title={params?.value}
            hideBorder
            hideIcon
            showClickCursor
            defaultShade={100}
            width="8rem"
            height="1.6rem"
            fontSize="0.7rem"
          />
        );
      },
    },
    {
      field: "scenario",
      headerName: t("Scenario"),
      flex: 0.75,
      editable: true,
      sortable: false,
      type: "singleSelect",
      valueOptions: (params) => {
        if (initialData.bookedState.includes(params?.row?.state)) {
          return [{ value: "Base", label: "Base" }];
        } else {
          return options.scenario;
        }
      },
      renderCell: (params) => {
        return (
          <ScenarioChip
            title={params?.value}
            hideBorder
            hideIcon
            showClickCursor
            width="7rem"
            height="1.6rem"
            fontSize="0.7rem"
          />
        );
      },
    },
    {
      field: "category",
      headerName: t("category"),
      headerClassName: "last-header-cell",
      // width: width * 0.088,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <strong style={{ display: "inline-flex", alignItems: "center" }}>
            <CategoryChip
              onClick={
                params?.row?.recurring_rule
                  ? undefined
                  : (e) => handleClick(e, params)
              }
              categoryId={params.value}
              showTooltip
              width={`${16 * 0.5}rem`}
              noCategoryLabel={"Click to set category"}
            />
            <div style={{ width: "1rem" }}>
              {params?.row?.parent ? (
                <Tooltip arrow title={t("Splitted Transactions")}>
                  <CallSplitIcon
                    sx={{
                      fontSize: "0.85rem",
                      color: Color.blueGrey400,
                      cursor: "default",
                      ml: "0.3rem !important",
                      alignSelf: "center",
                    }}
                  />
                </Tooltip>
              ) : null}
              {params?.row?.last_modified_rule ? (
                <Tooltip arrow title={t("Recently updated by finban")}>
                  <MdAutoAwesome
                    style={{
                      fontSize: "0.85rem",
                      color: Color.blueGrey400,
                      cursor: "default",
                      marginLeft: "0.3rem !important",
                      alignSelf: "center",
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>
          </strong>
        );
      },
    },
  ];
  const openColumn = [
    {
      field: "due_date",
      headerName: t("Date"),
      flex: 0.7,
      editable: !isPlanned,
      type: "date",
      cellClassName: "date-color",
      valueGetter: ({ value }) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: ({ value }) => {
        return value
          ? format(
              new Date(value),
              profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy"
            )
          : null;
      },
    },
    {
      field: "invoice_date",
      headerName: t("Invoice Date"),
      flex: 0.6,
      editable: !isPlanned,
      type: "date",
      cellClassName: "date-color",
      valueGetter: ({ value }) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: ({ value }) => {
        return value
          ? format(
              new Date(value),
              profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy"
            )
          : null;
      },
    },
    {
      field: "title",
      headerName: t("Title"),
      flex: 1.8,
      editable: !isPlanned,
    },
    {
      field: "note",
      headerName: t("Note"),
      flex: 1.8,
      editable: !isPlanned,
      sortable: false,
    },
    {
      field: "data_source",
      headerName: t("Data_Source"),
      flex: 0.7,
      editable: false,
      renderCell: (params) => {
        const dataSourceById = store?.getState()?.globalSlice?.dataSourceById;
        const ds = dataSourceById?.[params?.row?.data_source]?.[0];
        let card = getIntegrationCard({ ds });
        if (ds?.internal_dataset) {
          card = { title: "Internal data source" };
        }
        if (params.row?.source === 2) {
          card = { title: bankName?.[params.row?.account] };
        }
        return (
          <Tooltip
            title={t(
              card?.title === "Manual_DS_title"
                ? "Internal data source"
                : card?.title
            )}
          >
            <p
              style={{
                fontFamily: Fonts.Text,
                fontSize: "0.875rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {t(
                card?.title === "Manual_DS_title"
                  ? "Internal data source"
                  : card?.title
              )}
            </p>
          </Tooltip>
        );
      },
    },
    {
      field: "cost_unit",
      headerName: t("Cost_Unit"),
      flex: 0.6,
      editable: !isPlanned,
      sortable: false,
      type: "singleSelect",
      valueOptions: options.cost_unit,
      renderCell: (params) => {
        return (
          <Tooltip
            title={
              params?.value && costunitsById[params?.value]
                ? costunitsById[params?.value][0]?.title
                : ""
            }
          >
            <Typography
              variant="h6"
              component={"h6"}
              sx={{
                fontWeight: 400,
                fontFamily: Fonts.Text,
                fontSize: "0.875rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params?.value && costunitsById[params?.value]
                ? costunitsById[params?.value][0]?.title
                : ""}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "gross_value",
      headerName: t("Value"),
      flex: 0.8,
      editable: true,
      type: "float",
      renderCell: (params) => {
        return (
          <Tooltip
            title={formatAmount({
              amount: params?.value,
            })}
          >
            <p
              key={params?.row?.uuid}
              style={{
                color:
                  parseFloat(params.row?.gross_value) >= 0
                    ? Color.green
                    : Color.red,
                fontFamily: Fonts.Text,
                fontSize: "0.875rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {formatAmount({
                amount: params?.value,
              })}
            </p>
          </Tooltip>
        );
      },
    },
    {
      field: "tax",
      headerName: t("label_tax"),
      flex: 0.3,
      editable: !isPlanned,
      sortable: false,
      type: "singleSelect",
      valueOptions: options.vat,
      renderCell: (params) => {
        let data = options.vat?.find((o1) => o1.value === params?.row?.tax);

        return (
          <p
            key={params?.row?.uuid}
            style={{
              fontWeight: 400,
              fontFamily: Fonts.Text,
              fontSize: "0.875rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {data
              ? `${data?.label}`
              : params?.row?.source === 4
                ? `${(
                    ((params?.row?.gross_value -
                      params?.row?.calculated_net_value) *
                      100) /
                      params?.row?.calculated_net_value || 0
                  ).toFixed(1)}%`
                : ""}
          </p>
        );
      },
    },
    {
      field: "state",
      headerName: t("State"),
      flex: 0.8,
      editable: !isPlanned,
      sortable: false,
      type: "singleSelect",
      valueOptions: (params) => {
        if (params?.row?.source === 1) {
          return options.allState;
        } else if (params?.row?.state === "Planned") {
          return options.plannedState;
        } else if (initialData.bookedState.includes(params?.row?.state)) {
          return options.bookedState;
        } else {
          return options.openState;
        }
      },
      renderCell: (params) => {
        return (
          <StateChip
            title={params?.value}
            hideBorder
            hideIcon
            showClickCursor
            defaultShade={100}
            width="8rem"
            height="1.6rem"
            fontSize="0.7rem"
          />
        );
      },
    },
    {
      field: "scenario",
      headerName: t("Scenario"),
      flex: 0.8,
      editable: !isPlanned,
      sortable: false,
      type: "singleSelect",
      valueOptions: (params) => {
        if (initialData.bookedState.includes(params?.row?.state)) {
          return [{ value: "Base", label: "Base" }];
        } else {
          return options.scenario;
        }
      },
      renderCell: (params) => {
        return (
          <ScenarioChip
            title={params?.value}
            hideBorder
            hideIcon
            showClickCursor
            width="7rem"
            height="1.6rem"
            fontSize="0.7rem"
          />
        );
      },
    },
    {
      field: "category",
      headerName: t("category"),
      headerClassName: "last-header-cell",
      flex: 1,
      editable: !isPlanned,
      sortable: false,
      renderCell: (params) => {
        return (
          <strong style={{ display: "inline-flex", alignItems: "center" }}>
            <CategoryChip
              onClick={
                isPlanned || params?.row?.recurring_rule
                  ? undefined
                  : (e) => handleClick(e, params)
              }
              categoryId={params.value}
              showTooltip
              width={`${16 * 0.5}rem`}
              noCategoryLabel={"Click to set category"}
            />
            <div style={{ width: "1rem" }}>
              {params?.row?.last_modified_rule || params?.row?.parent ? (
                <Tooltip arrow title={t("Recently updated by finban")}>
                  <MdAutoAwesome
                    style={{
                      fontSize: "0.85rem",
                      color: Color.blueGrey400,
                      cursor: "default",
                      marginLeft: "0.3rem !important",
                      alignSelf: "center",
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>
          </strong>
        );
      },
    },
  ];
  const suggestionColumn = [
    {
      field: "due_date",
      headerName: t("Due Date"),
      flex: 1,
      editable: true,
      type: "date",
      cellClassName: "date-color",
      valueGetter: ({ value }) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: ({ value }) => {
        return value
          ? format(
              new Date(value),
              profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy"
            )
          : null;
      },
    },
    {
      field: "title",
      headerName: t("Title"),
      flex: 1,
      editable: true,
    },
    {
      field: "data_source",
      headerName: tab === "bankArray" ? t("Bank") : t("Data_Source"),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        let card = null;
        if (tab === "bankArray") {
          card = { title: bankName?.[params.row?.account] };
        } else {
          const dataSourceById = store?.getState()?.globalSlice?.dataSourceById;
          const ds = dataSourceById?.[params.row?.data_source];
          card = getIntegrationCard({ ds });
        }
        return (
          <Tooltip
            title={t(
              card?.title === "Manual_DS_title"
                ? "Internal data source"
                : card?.title
            )}
          >
            <p
              style={{
                fontFamily: Fonts.Text,
                fontSize: "0.875rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {t(
                card?.title === "Manual_DS_title"
                  ? "Internal data source"
                  : card?.title
              )}
            </p>
          </Tooltip>
        );
      },
    },

    {
      field: "note",
      headerName: t("Note"),
      flex: 1,
      editable: true,
      sortable: false,
    },
    {
      field: "gross_value",
      headerName: t("Value"),
      flex: 1,
      editable: true,
      type: "float",
      renderCell: (params) => {
        return (
          <Tooltip
            title={formatAmount({
              amount: params?.value,
            })}
          >
            <p
              key={params?.row?.uuid}
              style={{
                color:
                  parseFloat(params.row?.gross_value) >= 0
                    ? Color.green
                    : Color.red,
                fontFamily: Fonts.Text,
                fontSize: "0.875rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {formatAmount({
                amount: params?.value,
              })}
            </p>
          </Tooltip>
        );
      },
    },
    {
      field: "category",
      headerName: t("category"),
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <CategoryChip
            categoryId={params.value}
            showTooltip
            width={`${12}rem`}
            borderType="dashed"
            noCategoryLabel={"Click to set category"}
          />
        );
      },
    },
    {
      field: "suggested_category",
      headerName: t("suggested_category"),
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <CategoryChip
            categoryId={params.value}
            showTooltip
            width={`${12}rem`}
            borderType="dashed"
            noCategoryLabel={"Click to set category"}
          />
        );
      },
    },
    {
      field: "apply",
      headerName: "",
      headerClassName: "last-header-cell",
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip
            title={t("apply_suggestion_tooltip")}
            followCursor
            arrow
            placement="top"
          >
            <Button
              onClick={(e) => onClickApprove(e, params)}
              variant="outlined"
              sx={{
                p: 0,
                width: "fit-content",
                minWidth: "fit-content",
                px: "0.5rem",
                textTransform: "initial",
                fontSize: "1rem",
                fontFamily: Fonts.Text,
              }}
            >
              {t("apply_suggestion")}
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  // const unCatColumn = [
  //   {
  //     field: "due_date",
  //     headerName: t("Due Date"),
  //     width: width * 0.07,
  //     editable: true,
  //     type: "date",
  //     cellClassName: "date-color",
  //     valueGetter: ({ value }) => {
  //       return value ? new Date(value) : null;
  //     },
  //     valueFormatter: ({ value }) => {
  //       return value
  //         ? format(
  //             new Date(value),
  //             profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy"
  //           )
  //         : null;
  //     },
  //   },
  //   {
  //     field: "invoice_date",
  //     headerName: t("Invoice Date"),
  //     width: width * 0.07,
  //     editable: true,
  //     type: "date",
  //     cellClassName: "date-color",
  //     valueGetter: ({ value }) => {
  //       return value ? new Date(value) : null;
  //     },
  //     valueFormatter: ({ value }) => {
  //       return value
  //         ? format(
  //             new Date(value),
  //             profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy"
  //           )
  //         : null;
  //     },
  //   },
  //   {
  //     field: "title",
  //     headerName: t("Title"),
  //     width: width * 0.1,
  //     editable: true,
  //   },
  //   // {
  //   //   field: "contact",
  //   //   headerName: t("Contact"),
  //   //   width: width * 0.08,
  //   //   editable: true,
  //   //   sortable: false,
  //   //   type: "singleSelect",
  //   //   valueOptions: contactData.option,
  //   //   renderCell: (params) => {
  //   //     return (
  //   //       <Tooltip
  //   //         title={
  //   //           params?.value && contactsById[params?.value]
  //   //             ? contactsById[params?.value][0]?.name
  //   //             : ""
  //   //         }
  //   //       >
  //   //         <Typography
  //   //           variant="h6"
  //   //           component={"h6"}
  //   //           sx={{
  //   //             fontWeight: 400,
  //   //             fontFamily: Fonts.Text,
  //   //             fontSize: "0.875rem",
  //   //             overflow: "hidden",
  //   //             textOverflow: "ellipsis",
  //   //             whiteSpace: "nowrap",
  //   //           }}
  //   //         >
  //   //           {params?.value && contactsById[params?.value]
  //   //             ? contactsById[params?.value][0]?.name
  //   //             : ""}
  //   //         </Typography>
  //   //       </Tooltip>
  //   //     );
  //   //   },
  //   // },
  //   {
  //     field: "data_source",
  //     headerName: t("Data_Source"),
  //     width: width * 0.08,
  //     editable: false,
  //     renderCell: (params) => {
  //       let card = null;
  //       if (params.row?.source === 4) {
  //         card = { title: params.row?.transaction_system };
  //       } else {
  //         let typ = params.row?.source === 2 ? 10 : params.row?.source;
  //         card =
  //           (IntegrationsByType && IntegrationsByType[typ] && IntegrationsByType[typ][0]) ?? null;
  //       }
  //       return (
  //         <Tooltip
  //           title={t(
  //             card?.title === "Manual_DS_title"
  //               ? "Internal data source"
  //               : card?.title
  //           )}
  //         >
  //           <p
  //             style={{
  //               fontFamily: Fonts.Text,
  //               fontSize: "0.875rem",
  //               overflow: "hidden",
  //               textOverflow: "ellipsis",
  //               whiteSpace: "nowrap",
  //             }}
  //           >
  //             {t(
  //               card?.title === "Manual_DS_title"
  //                 ? "Internal data source"
  //                 : card?.title
  //             )}
  //           </p>
  //         </Tooltip>
  //       );
  //     },
  //   },
  //   {
  //     field: "cost_unit",
  //     headerName: t("Cost_Unit"),
  //     width: width * 0.07,
  //     editable: true,
  //     sortable: false,
  //     type: "singleSelect",
  //     valueOptions: options.cost_unit,
  //     renderCell: (params) => {
  //       return (
  //         <Tooltip
  //           title={
  //             params?.value && costunitsById[params?.value]
  //               ? costunitsById[params?.value][0]?.title
  //               : ""
  //           }
  //         >
  //           <Typography
  //             variant="h6"
  //             component={"h6"}
  //             sx={{
  //               fontWeight: 400,
  //               fontFamily: Fonts.Text,
  //               fontSize: "0.875rem",
  //               overflow: "hidden",
  //               textOverflow: "ellipsis",
  //               whiteSpace: "nowrap",
  //             }}
  //           >
  //             {params?.value && costunitsById[params?.value]
  //               ? costunitsById[params?.value][0]?.title
  //               : ""}
  //           </Typography>
  //         </Tooltip>
  //       );
  //     },
  //   },
  //   {
  //     field: "note",
  //     headerName: t("Note"),
  //     width: width * 0.08,
  //     editable: true,
  //     sortable: false,
  //   },
  //   {
  //     field: "gross_value",
  //     headerName: t("Value"),
  //     width: width * 0.1,
  //     editable: true,
  //     type: "float",
  //     renderCell: (params) => {
  //       return (
  //         <Typography
  //           variant="h6"
  //           component={"h6"}
  //           sx={{
  //             color:
  //               parseFloat(params.row?.gross_value) >= 0
  //                 ? Color.green
  //                 : Color.red,
  //             fontFamily: Fonts.Text,
  //             fontSize: "0.875rem",
  //           }}
  //         >
  //           {formatAmount({
  //             amount: String(parseFloat(params?.value).toFixed(2)),
  //           })}
  //         </Typography>
  //       );
  //     },
  //     valueGetter: ({ value }) => parseFloat(value),
  //   },
  //   {
  //     field: "tax",
  //     headerName: t("label_tax"),
  //     width: width * 0.055,
  //     editable: true,
  //     sortable: false,
  //     type: "singleSelect",
  //     valueOptions: options.vat,
  //     valueFormatter: (params) => {
  //       let data = options.vat?.find((o1) => o1.value === params.value);
  //       return data ? `${data?.label}` : null;
  //     },
  //   },
  //   {
  //     field: "state",
  //     headerName: t("State"),
  //     width: width * 0.07,
  //     editable: true,
  //     type: "singleSelect",
  //     valueOptions: (params) => {
  //       if (params?.row?.source === 1) {
  //         return options.allState;
  //       }
  //       if (params?.row?.state === "Planned") {
  //         return options.plannedState;
  //       } else if (initialData.bookedState.includes(params?.row?.state)) {
  //         return options.bookedState;
  //       } else {
  //         return options.openState;
  //       }
  //     },
  //     renderCell: (params) => {
  //       return (
  //         <StateChip
  //           title={params?.value}
  //           hideBorder
  //           hideIcon
  //           showClickCursor
  //           width="8rem"
  //           height="1.6rem"
  //           fontSize="0.7rem"
  //         />
  //       );
  //     },
  //   },
  //   {
  //     field: "scenario",
  //     headerName: t("Scenario"),
  //     width: width * 0.07,
  //     editable: true,
  //     sortable: false,
  //     type: "singleSelect",
  //     valueOptions: (params) => {
  //       if (initialData.bookedState.includes(params?.row?.state)) {
  //         return [{ value: "Base", label: "Base" }];
  //       } else {
  //         return options.scenario;
  //       }
  //     },
  //     renderCell: (params) => {
  //       return (
  //         <ScenarioChip
  //           title={params?.value}
  //           hideBorder
  //           hideIcon
  //           showClickCursor
  //           width="8rem"
  //           height="1.6rem"
  //           fontSize="0.7rem"
  //         />
  //       );
  //     },
  //   },
  //   {
  //     field: "category",
  //     headerName: t("category"),
  //     width: width * 0.08,
  //     editable: false,
  //     sortable: false,
  //     renderCell: (params) => {
  //       return (
  //         <strong>
  //           <CategoryChip
  //             onClick={(e) => handleClick(e, params)}
  //             categoryId={null}
  //             count={15}
  //             showTooltip
  //             width={`${16 * 0.5}rem`}
  //           />
  //         </strong>
  //       );
  //     },
  //   },
  //   {
  //     field: "rule",
  //     headerName: t("Category & Rule"),
  //     width: width * 0.1,
  //     editable: false,
  //     sortable: false,
  //     renderCell: (params) => {
  //       return (
  //         <strong>
  //           <Chip
  //             onClick={(e) => handleClickRule(e, params)}
  //             label={"Set category & Add Rule"}
  //             sx={{
  //               fontSize: "0.75rem",
  //               fontWeight: 600,
  //               height: "1.5625rem",
  //               minWidth: "5.625rem",
  //               borderRadius: 1,
  //               fontFamily: Fonts.Text,
  //               color: alpha(Color.themeColor, 0.75),
  //               backgroundColor: Color.blueGrey50,
  //               border: `1px solid ${Color.blueGrey400}`,
  //             }}
  //           />
  //         </strong>
  //       );
  //     },
  //   },
  // ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = {
    allArray: sameColumn,
    catArray: sameColumn,
    suggestionArray: suggestionColumn,
    bankArray: sameColumn,
    openArray: openColumn,
    plannedArray: openColumn,
    // unCatArray: unCatColumn,
    lateArray: sameColumn,
    issueArray: sameColumn,
  };

  // lifeCycle method
  useEffect(() => {
    if (page === 0) {
      setUpdateHelper(Date.now());
    }
  }, [refreshData]);

  useEffect(() => {
    if (dataSetData?.uuid && isAllHeaderApiFetched && !listLoading) {
      let data = {};
      datasetAccountList?.forEach((element) => {
        if (element?.type === 10 || element?.type === 12) {
          data[element?.account?.uuid] =
            element?.title ||
            element?.account?.bank_details?.title ||
            t(element?.card?.title);
        }
      });

      setBankName(data);
    }
  }, [
    datasetAccountList,
    isAllHeaderApiFetched,
    dataSetData?.uuid,
    listLoading,
    t,
  ]);

  useEffect(() => {
    if (dataSetData?.uuid && isAllHeaderApiFetched) {
      let array = location.pathname.split("/");
      let key = array[array.length - 1];
      if (key !== "list") {
        const filter = tabOptions?.find((o1) => o1?.key === key);
        if (filter) {
          setTab(filter?.value);
        }
      } else {
        setTab("bulkUnCatArray");
      }
    }
  }, [dataSetData?.uuid, location.pathname, isAllHeaderApiFetched]);

  useEffect(() => {
    if (
      dataSetData?.uuid &&
      isAllHeaderApiFetched &&
      !isAccountSelectOverlayOpen
    ) {
      let costunits_option = [];
      let scenarios_option = [];
      let stats_option = [];
      let open_stats_option = [];
      let bookedOption = [
        {
          value: "Booked",
          label: t("Booked"),
        },
        {
          value: "Ignored",
          label: t("Ignored"),
        },
      ];
      let vats_option = [];
      // let contacts_option = [];
      //   contacts?.forEach((element) => {
      //     contacts_option.push({
      //       value: element.uuid,
      //       label: element.name,
      //     });
      //   });
      costunits?.forEach((element) => {
        costunits_option.push({
          value: element.uuid,
          label: element.title,
        });
      });
      state.forEach((element) => {
        stats_option.push({
          value: element.title,
          label: t(element.title),
        });

        if (!initialData.excludeForOpenState.includes(element?.title)) {
          open_stats_option.push({
            value: element.title,
            label: t(element.title),
          });
        }
      });
      scenario?.forEach((element) => {
        scenarios_option.push({
          value: element.title,
          label: element?.title === "Base" ? t(element?.title) : element?.title,
        });
      });
      vat?.forEach((element) => {
        vats_option.push({
          value: element.uuid,
          label: element.title,
        });
      });
      setOptions({
        allState: stats_option,
        openState: open_stats_option,
        plannedState: [
          {
            value: "Planned",
            label: t("Planned"),
          },
        ],
        bookedState: bookedOption,
        scenario: scenarios_option,
        vat: vats_option,
        cost_unit: costunits_option,
        // contact:contacts_option
      });
    }
  }, [
    costunits,
    scenario,
    state,
    vat,
    isAllHeaderApiFetched,
    isAccountSelectOverlayOpen,
    dataSetData?.uuid,
    t,
  ]);

  useEffect(() => {
    if (
      tab &&
      tab !== "bulkUnCatArray" &&
      tab !== "reconcileArray" &&
      allowUpdate &&
      dataSetData?.uuid &&
      isAllHeaderApiFetched
    ) {
      setPage(0);
    }
  }, [
    appliedFilterlist?.list,
    tab,
    allowUpdate,
    order_by,
    refreshData,
    dataSetData?.uuid,
    isAllHeaderApiFetched,
  ]);

  useDebounce(
    () => {
      if (
        tab &&
        tab !== "bulkUnCatArray" &&
        tab !== "reconcileArray" &&
        allowUpdate &&
        dataSetData?.uuid &&
        isAllHeaderApiFetched
      ) {
        updateList();
      }
    },
    500,
    [
      profile,
      options,
      appliedFilterlist?.list,
      tab,
      page,
      allowUpdate,
      order_by,
      updateHelper,
      dataSetData?.uuid,
      isAllHeaderApiFetched,
    ]
  );

  useDebounce(
    () => {
      if (
        selectedRowData?.length > 0 &&
        dataSetData?.uuid &&
        isAllHeaderApiFetched
      ) {
        dispatch(
          setPopupStatus5({
            overlay_type: "all",
            payload: { selectedRowData: selectedRowData, resetKanban },
          })
        );
      } else {
        dispatch(setPopupStatus5(null));
      }
    },
    500,
    [selectedRowData],
    true
  );

  //api
  const updateCardByID = async (id, obj) => {
    await APICall("patch", EndPoints.transactions + `${id}/`, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setStageLoadingText(null));
        let resetObj = null;
        if (obj?.hasOwnProperty("state")) {
          resetObj = {
            refetchBalance: true,
          };
        }
        resetKanban(resetObj);
      }
      if (response.data?.category?.[0]?.includes("Cannot set category.")) {
        rulesRef.current?.onOpen({
          payload: [{ ...obj, uuid: id }],
          response: [{ ...response?.data, uuid: id }],
          message: response?.data?.category?.[0],
          transaction_rule: response?.data?.transaction_rule?.[0],
          rule_type: response?.data?.type?.[0],
          title: itemRow.current?.title,
          transaction_type: itemRow.current?.gross_value >= 0 ? 1 : 2,
          category: obj?.category,
        });
      }
    });
  };

  const getData = async (page, endUrl = "") => {
    setListLoading(true);
    const response = await getTransactionByParams({
      dataset: dataSetData?.uuid,
      page: page + 1,
      page_size: pageSize,
      order_by: order_by,
      is_reconciled: false,
      detailed: 2,
      url: endUrl,
    });
    if (response) {
      setRowCountState(response?.count);
      setRows([...(response?.results || [])]);
    }
    setListLoading(false);
  };

  const getDataLate = async (page, url1 = "", url2 = "", tab) => {
    setListLoading(true);
    const response1 = await getTransactionByParams({
      dataset: dataSetData?.uuid,
      page: page + 1,
      page_size: 20,
      is_reconciled: false,
      detailed: 2,
      order_by: "-due_date",
      url: url1,
    });
    const response2 = await getTransactionByParams({
      dataset: dataSetData?.uuid,
      page: page + 1,
      page_size: 20,
      is_reconciled: false,
      detailed: 2,
      order_by: "-due_date",
      url: url2,
    });
    let count = response1?.count + response2?.count;
    setRowCountState(count);
    setRows([...(response1?.results || []), ...(response2?.results || [])]);
    setListLoading(false);
  };

  const resetKanban = async (resetObj) => {
    if (tab !== "bulkUnCatArray" && tab !== "reconcileArray" && allowUpdate) {
      if (Number(page) === 0) {
        updateList();
      } else {
        setPage(0);
      }
    }
    global.allowFetch = { Inflow: [], Outflow: [] };
    let options = {
      predicate: (query) => query.queryKey[0] === "transactions",
    };
    queryClient.resetQueries(options);
    if (resetObj?.refetchBalance) {
      dispatch(setRefreshBalance(Date.now()));
    }
  };

  //function
  const getFilterListUrl = (filterList) => {
    let {
      searchText,
      selectedStates,
      selectedScenarios,
      selectedCategory,
      selectedCostUnits,
      typeName,
      source,
      start_date,
      end_date,
      date_type,
    } = filterList;
    let url = "&is_split=false";
    const use_global_categories =
      store.getState().boardSlice?.dataSetData?.use_global_categories;
    if (!use_global_categories) {
      url = url + `&category_dataset=${dataSetData?.uuid}`;
    } else {
      url = url + `&global_category=true`;
    }
    if (searchText && searchText?.length > 0) {
      url = url + `&title_or_note=${searchText}`;
    }
    if (typeName?.includes("Income") && !typeName?.includes("Expense")) {
      url = url + "&min_gross_value=0";
    }
    if (!typeName?.includes("Income") && typeName?.includes("Expense")) {
      url = url + "&max_gross_value=-0.001";
    }
    if (!typeName?.includes("Income") && !typeName?.includes("Expense")) {
      url = url + "&max_gross_value=-0.001&min_gross_value=1";
    }
    if (start_date) {
      if (!date_type || date_type === 1) {
        url = url + `&from_date=${format(new Date(start_date), "yyyy-MM-dd")}`;
      } else {
        url =
          url +
          `&from_invoice_date=${format(new Date(start_date), "yyyy-MM-dd")}`;
      }
    }
    if (end_date) {
      if (!date_type || date_type === 1) {
        url = url + `&to_date=${format(new Date(end_date), "yyyy-MM-dd")}`;
      } else {
        url =
          url + `&to_invoice_date=${format(new Date(end_date), "yyyy-MM-dd")}`;
      }
    }
    if (!hideCategory && selectedCategory?.length > 0) {
      const isUncategorized = selectedCategory?.includes(
        "unCategorized_category"
      );

      const selectionCategoriesByID =
        store?.getState()?.categorySlice?.selectionCategoriesByID;
      if (selectedCategory?.length === 1 && isUncategorized) {
        url = url + "&categorized=false";
      } else {
        selectedCategory?.forEach((o1) => {
          const _category = selectionCategoriesByID?.[o1]?.[0]?.uuid;
          if (_category) {
            if (isUncategorized) {
              url = url + `&uncategorized_or_with_category=${_category}`;
            } else {
              url = url + `&category=${_category}`;
            }
          }
        });
      }
    }
    if (!hideState && tab !== "openArray" && selectedStates?.length > 0) {
      const stateById = store?.getState()?.globalSlice?.stateById;
      selectedStates.forEach((item) => {
        const _state = stateById?.[item]?.[0]?.uuid;
        if (_state) {
          url = url + `&state=${_state}`;
        }
      });
    }
    if (!hideScenario && selectedScenarios?.length > 0) {
      selectedScenarios.forEach((item) => {
        const _scenario = scenarioById?.[item]?.[0]?.uuid;
        if (_scenario) {
          url = url + `&scenario=${_scenario}`;
        }
      });
    }
    if (!hideCostUnit && selectedCostUnits?.length > 0) {
      url = url + `&cost_unit=${selectedCostUnits[0]}`;
    }

    if (tab === "allArray") {
      if (source?.length > 0) {
        source?.forEach((item) => {
          const _source = Constant?.DefaultSourceByName?.[item];
          if (_source) {
            url = url + `&source=${_source}`;
          }
        });
      }
    }

    return url;
  };

  const onClickApprove = (e, params) => {
    e.stopPropagation();
    updateCardByID(params?.row?.uuid, {
      category: params?.row?.suggested_category,
    });
  };

  const updateList = () => {
    setListLoading(true);
    setSelectedRowData([]);
    setSelectedRowIds([]);

    if (appliedFilterlist?.list) {
      let {
        selectedOpenStates,
        selectedBankStates,
        selectedCategory,
        source,
        selectedDataSource,
      } = appliedFilterlist?.list;
      let url = getFilterListUrl(appliedFilterlist?.list);
      if (tab === "issueArray") {
        url = url + "&missing_date=true";
      }
      if (
        tab === "catArray" &&
        (!selectedCategory || selectedCategory?.length === 0)
      ) {
        url = url + "&categorized=true";
      }
      if (tab === "unCatArray") {
        url = url + "&categorized=false";
      }
      if (tab === "bankArray") {
        url = url + `&source=2`;
      }
      if (tab === "plannedArray") {
        url = url + `&state=${stateByTitle?.["Planned"]?.[0]?.uuid}`;
      }
      if (tab === "suggestionArray") {
        url = url + `&category_suggested=true`;
      }
      if (tab === "openArray") {
        if (selectedOpenStates?.length > 0) {
          selectedOpenStates.forEach((id) => {
            url = url + `&state=${id}`;
          });
        } else {
          options?.openState?.forEach((item) => {
            const _state = stateByTitle?.[item?.value]?.[0]?.uuid;
            if (_state) {
              url = url + `&state=${_state}`;
            }
          });
        }
      }
      if (tab === "bankArray" && selectedBankStates?.length > 0) {
        selectedBankStates.forEach((id) => {
          url = url + `&state=${id}`;
        });
      }
      if (tab === "lateArray") {
        const currentDate = format(new Date(), "yyyy-MM-dd");

        let url1 =
          url +
          `&from_invoice_date=&to_invoice_date=${currentDate}&missing_date=true`;
        let url2 = url + `&from_date=&to_date=${currentDate}`;

        Constant.openState.forEach((item) => {
          let _state = stateByTitle?.[item]?.[0]?.uuid;
          if (_state) {
            url1 = url1 + `&state=${_state}`;
            url2 = url2 + `&state=${_state}`;
          }
        });
        getDataLate(page, url1, url2, tab);

        return;
      }
      if (tab === "allArray") {
        if (selectedDataSource?.length > 0) {
          selectedDataSource?.forEach((id) => {
            url = url + `&data_source=${id}`;
          });
        }
        if (source?.length > 0) {
          source?.forEach((item) => {
            const _source = Constant?.DefaultSourceByName?.[item];
            if (_source) {
              url = url + `&source=${_source}`;
            }
          });
        } else {
          setRowCountState(0);
          setRows([]);
          setListLoading(false);
          return;
        }
      }

      if (tab === "suggestionArray") {
        if (Number(profile?.auto_categorization) === 2) {
          getData(page, url, tab);
        } else {
          setListLoading(false);
          setRowCountState(0);
        }
      } else {
        getData(page, url, tab);
      }
    }
  };

  const updateData = (tabValue) => {
    setRows([]);
  };

  const handleChangeTab = (event, newValue) => {
    if (tab !== newValue) {
      setTab(newValue);
      setSelectedRowData([]);
      setSelectedRowIds([]);
      setPage(0);
      updateData(newValue);
      const key = tabOptions?.find((o1) => o1?.value === newValue);
      let array = location.pathname.split("/");
      array.pop();
      array.push(key.key);
      let url = array.join("/");
      navigate(url);
    }
  };

  const onCellEditCommit = (data) => {
    let obj;
    if (data.field === "due_date") {
      obj = {
        due_date: data.value
          ? format(new Date(data.value), "yyyy-MM-dd")
          : null,
      };
      if (data.value) {
        obj.invoice_date = null;
      }
    } else if (data.field === "invoice_date") {
      obj = {
        invoice_date: data.value
          ? format(new Date(data.value), "yyyy-MM-dd")
          : null,
      };
    } else if (
      data?.field === "state" &&
      initialData.bookedState.includes(data?.value)
    ) {
      obj = {
        [data.field]: data.value,
        scenario: "Base",
      };
    } else if (data.field === "tax") {
      obj = {
        tax: data.value,
        tax_value: null,
      };
    } else {
      obj = {
        [data.field]: data.value,
      };
    }

    updateCardByID(data.id, obj);
  };

  const handleClick = (e, item) => {
    itemRow.current = item.row;
    onCellDoubleClick();
    dispatch(
      setPopupStatus4({
        open: true,
        anchorEl: e.currentTarget,
        overlay_type: "category",
        payload: {
          type: item.row.gross_value >= 0 ? 1 : 2,
          onClickCategoryTitle: onClickCategoryTitle,
          ruleTitle: item?.row?.title,
        },
      })
    );
  };

  const onClickCategoryTitle = (e, item) => {
    updateCardByID(itemRow.current.uuid, { category: item.uuid });
    closeCategoryOverlay();
  };

  const closeCategoryOverlay = () => {
    dispatch(setPopupStatus4(null));
  };

  const onSelectionModelChange = (ids) => {
    const selectedIDs = new Set(ids);
    const updateData = rows.filter((row) =>
      selectedIDs.has(row.uuid.toString())
    );
    if (updateData?.length > 0) {
      updateType.current = "batch";
    } else {
      updateType.current = "single";
    }
    setSelectedRowIds(ids);
    setSelectedRowData(updateData);
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const onPageSizeChange = (size) => {
    setPageSize(size);
    if (page !== 0) {
      setPage(0);
    } else {
      dispatch(setRefreshData(Date.now()));
    }
  };

  const onSortModelChange = (params) => {
    if (params && params[0]) {
      let { field, sort } = params[0];
      if (field === "title") {
        if (sort === "asc") {
          setOrder_by("title");
        } else {
          setOrder_by("-title");
        }
      }
      if (field === "due_date") {
        if (sort === "asc") {
          setOrder_by("due_date");
        } else {
          setOrder_by("-due_date");
        }
      }
      if (field === "invoice_date") {
        if (sort === "asc") {
          setOrder_by("invoice_date");
        } else {
          setOrder_by("-invoice_date");
        }
      }
      if (field === "gross_value") {
        if (sort === "asc") {
          setOrder_by("gross_value");
        } else {
          setOrder_by("-gross_value");
        }
      }
    } else {
      setOrder_by("");
    }
  };

  const onRowClick = (params) => {
    timer.current = setTimeout(() => {
      if (!prevent.current) {
        dispatch(
          setTransactionsOverlayStatus({
            open: true,
            overlay_type: "drawer_modal_edit_form",
            anchor: "right",
            payload: {
              modalType: "edit",
              transaction_type:
                params?.row?.gross_value >= 0 ? "income" : "expense",
              updateOverlay: resetKanban,
              item: params.row,
            },
          })
        );
      }
      prevent.current = false;
    }, delay.current);
  };

  const onCellDoubleClick = () => {
    clearTimeout(timer.current);
    prevent.current = true;
  };

  const getCellClassName = (params) => {
    if (
      params.row?.recurring_rule ||
      (params.row?.source === 2 &&
        params.field !== "state" &&
        params.field !== "category")
    ) {
      return "super-app-theme--cell";
    } else {
      return "";
    }
  };

  const isCellEditable = (params) => {
    return params.row?.recurring_rule
      ? false
      : params.row?.source !== 2
        ? true
        : params?.field === "state";
  };


  return (
    <Box className={classes.root}>
      {isAllHeaderApiFetched && (
        <>
          <RulesCommonView ref={rulesRef} />
          <ListHeader
            width={width}
            setAllowUpdate={setAllowUpdate}
            hideCostUnit={hideCostUnit}
            hideCategory={hideCategory}
            hideState={hideState}
            hideSource={hideSource}
            hideScenario={hideScenario}
            optionsType={
              tab === "openArray" ? "Open" : tab === "bankArray" ? "Bank" : null
            }
            tab={tab}
          />
          <Box
            sx={{
              display: "flex",
              alignItem: "center",
              justifyContent: "space-between",
              width: width,
              maxWidth: "85%",
              mt: "1rem",
            }}
          >
            <ThemeTabs
              options={tabOptions}
              tab={tab}
              onChange={handleChangeTab}
            />
            
              <ExportDialogView
                rowCountState={rowCountState}
                headerData={columns?.[tab]?.slice() || []}
                getFilterListUrl={getFilterListUrl}
                order_by={order_by}
                bankName={bankName}
                setTab={setTab}
              />
          </Box>
          {tab === "bulkUnCatArray" ? <Divider sx={{ width: width }} /> : null}
          {tab === "bulkUnCatArray" ? (
            <BulkListView allowUpdate={allowUpdate} width={width} />
          ) : null}
          {tab && tab !== "bulkUnCatArray" ? (
            <Paper
              sx={{
                boxShadow:
                  tab === "bulkUnCatArray"
                    ? "none !important"
                    : "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                width: width,
                borderRadius: "5px",
                backgroundColor:
                  tab === "bulkUnCatArray" ? "transparent" : Color.white,
                maxHeight: "80%",
                height: 20 * remToPx(currentWidth, HEIGHT) + spacing,
                position: "relative",
                "& .MuiDataGrid-overlay": {
                  zIndex: 1,
                },
                "& .MuiDataGrid-columnHeaders": {
                  border: 0,
                },
                "& .MuiDataGrid-columnHeader": {
                  outline: "none",
                },
                "& .MuiDataGrid-columnHeader:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  width: width,
                  position: "absolute",
                  left: 0,
                },
                "& .MuiDataGrid-cell": {
                  borderColor: theme.palette.color.slate[100],
                  padding: "0 6px",
                },
                "& .super-app-theme--cell": {
                  backgroundColor: alpha(Color.headerIconLight, 0.3),
                  borderColor: theme.palette.color.slate[200],
                },
                "& .divider-cell": {
                  backgroundColor: alpha(Color.headerIconLight, 0.3),
                },
                "& .date-color": {
                  color: tab === "lateArray" ? Color.red : Color.black,
                },
              }}
            >
              {tab === "reconcileArray" ? (
                <ReconcileListView
                  rowCountState={rowCountState}
                  setRowCountState={setRowCountState}
                  allowUpdate={allowUpdate}
                />
              ) : (
                <DataGrid
                  className={classes.table}
                  rows={rows}
                  showColumnRightBorder={false}
                  // autoPageSize
                  columns={columns?.[tab]?.slice()}
                  disableColumnMenu
                  disableSelectionOnClick
                  rowHeight={remToPx(currentWidth, HEIGHT)}
                  checkboxSelection
                  getRowId={(row) => row?.uuid}
                  onCellEditCommit={onCellEditCommit}
                  selectionModel={selectedRowIds}
                  onSelectionModelChange={onSelectionModelChange}
                  getCellClassName={getCellClassName}
                  isCellEditable={isCellEditable}
                  rowCount={rowCountState}
                  onSortModelChange={onSortModelChange}
                  onRowClick={onRowClick}
                  onCellDoubleClick={onCellDoubleClick}
                  page={page}
                  onPageChange={onPageChange}
                  pagination
                  // pageSize={20}
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                  rowsPerPageOptions={[20, 50, 100]}
                  paginationMode={"server"}
                  loading={listLoading}
                  localeText={{
                    noRowsLabel: "",
                    noResultsOverlayLabel: "",
                  }}
                  componentsProps={{
                    footer: {
                      selectedRowData,
                      tab,
                      rowCountState,
                      listLoading,
                    },
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Footer: CombinedDataGridFooter,
                  }}
                  sx={{
                    border: 0,
                  }}
                />
              )}
            </Paper>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default Transactions;
