import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";
import { useTheme } from "@mui/material";
import { useState } from "react";

import FInbanInfoContent from "./FinbanInfoContent";
import CustomModal from "../Model/CustomModal";

const FinbanInfoOverlay = ({ url }) => {
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);

  const onClickLink = () => {
    // setOpen(true);
    window.open(url, "_blank");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isOpen ? (
        <CustomModal
          hideAction
          open={isOpen}
          onClose={handleClose}
          positioned
          PaperPropsSx={{
            m: 0,
            top: 0,
            right: 0,
            width: "40rem",
            maxWidth: "90vw",
            maxHeight: "none",
            height: "100%",
            borderRadius: "2px",
          }}
        >
          <FInbanInfoContent url={url} />
        </CustomModal>
      ) : null}
      <TipsAndUpdatesTwoToneIcon
        onClick={onClickLink}
        sx={{
          color: theme.palette.color.amber[300],
          cursor: "pointer",
          mx: "1rem",
          "&:hover": {
            color: theme.palette.color.amber[500],
          },
        }}
      />
    </>
  );
};

export default FinbanInfoOverlay;
