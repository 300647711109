import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import ComponentLoader from "../ComponentLoader";

const FInbanInfoContent = ({ url }) => {
  const [content, setContent] = useState("");
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (url) {
      setFetching(true);
      fetch(url)
        .then((response) => response.text())
        .then((html) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          // Extract all stylesheets from the fetched HTML
          const stylesheets = Array.from(
            doc.querySelectorAll('link[rel="stylesheet"]')
          );

          // Inject the stylesheets into the current document head
          stylesheets.forEach((link) => {
            const newLink = document.createElement("link");
            newLink.rel = "stylesheet";
            newLink.href = link.href;
            document.head.appendChild(newLink);
          });

          const specificSection = doc.querySelector(`#content`);

          if (specificSection) {
            setContent(specificSection?.innerHTML);
          } else {
            setContent(null);
          }
          setFetching(false);
        })
        .catch((err) => {
          setFetching(false);

          console.log(url, "==>", "featch failed");
        });
    }
  }, [url]);

  // Prevent default behavior of <a> tags when clicked
  const handleLinkClick = (event) => {
    if (event.target.tagName === "A") {
      event.preventDefault();
    }
  };

  if (fetching) {
    return (
      <ComponentLoader
        loading
        hideNoDataPlaceholder
        height="100%"
        sx={{
          width: "100%",
        }}
      />
    );
  }

  if (!fetching && content === null) {
    return null;
  }

  return (
    <Box
      dangerouslySetInnerHTML={{ __html: content }}
      onClick={handleLinkClick}
      sx={{
        p: "2rem",
        "& .page__header": {
          display: "none",
        },
        "& .finban_overlay_hide_component": {
          display: "none",
        },
      }}
    />
  );
};

export default FInbanInfoContent;
