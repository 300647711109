import {
  dropTargetForElements,
  monitorForElements,
  draggable,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  Fragment,
  useMemo,
} from "react";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import {
  extractClosestEdge,
  attachClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { triggerPostMoveFlash } from "@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import * as liveRegion from "@atlaskit/pragmatic-drag-and-drop-live-region";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import ReactDOM from "react-dom/client";
import invariant from "tiny-invariant";
import React from "react";

import { getItemData, getItemRegistry, isItemData } from "./helper";

export const idleState = { type: "idle" };
export const draggingState = { type: "dragging" };
const ListContext = createContext(null);

export const useListContext = () => {
  const listContext = useContext(ListContext);
  invariant(
    listContext !== null,
    "useListContext must be used within ListProvider"
  );
  return listContext;
};

export default function DNDListProvider({
  children,
  items,
  setItems,
  instanceID = "instance-id",
  updateDataBeforeSave,
}) {
  const [instanceId] = useState(() => Symbol(instanceID));
  const [registry] = useState(getItemRegistry);
  const [lastCardMoved, setLastCardMoved] = useState(null);

  const reorderItem = useCallback(
    ({ startIndex, indexOfTarget, closestEdgeOfTarget }) => {
      const finishIndex = getReorderDestinationIndex({
        startIndex,
        closestEdgeOfTarget,
        indexOfTarget,
        axis: "vertical",
      });

      if (finishIndex === startIndex) {
        // If there would be no change, we skip the update
        return;
      }
      const item = items?.[startIndex];
      let updatedItems = reorder({
        list: items,
        startIndex,
        finishIndex,
      });
      if (updateDataBeforeSave) {
        updatedItems = updateDataBeforeSave(updatedItems);
      }
      setItems({ items: updatedItems });
      setLastCardMoved({
        item,
        previousIndex: startIndex,
        currentIndex: finishIndex,
        numberOfItems: items?.length,
      });
    },
    [items, setItems, updateDataBeforeSave]
  );

  const getListLength = useCallback(() => items?.length, [items?.length]);

  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return isItemData(source.data) && source.data.instanceId === instanceId;
      },
      onDrop({ location, source }) {
        const target = location.current.dropTargets[0];
        if (!target) {
          return;
        }

        const sourceData = source.data;
        const targetData = target.data;
        if (!isItemData(sourceData) || !isItemData(targetData)) {
          return;
        }

        const indexOfTarget = items.findIndex(
          (item) => item?.uuid === targetData.item?.uuid
        );
        if (indexOfTarget < 0) {
          return;
        }

        const closestEdgeOfTarget = extractClosestEdge(targetData);

        reorderItem({
          startIndex: sourceData.index,
          indexOfTarget,
          closestEdgeOfTarget,
        });
      },
    });
  }, [instanceId, items, reorderItem]);

  // once a drag is finished, we have some post drop actions to take
  useEffect(() => {
    if (lastCardMoved === null) {
      return;
    }

    const { item, previousIndex, currentIndex, numberOfItems } = lastCardMoved;
    const element = registry.getElement(item?.uuid);
    if (element) {
      triggerPostMoveFlash(element);
    }

    liveRegion.announce(
      `You've moved ${item?.label} from position ${
        previousIndex + 1
      } to position ${currentIndex + 1} of ${numberOfItems}.`
    );
  }, [lastCardMoved, registry]);

  // cleanup the live region when this component is finished
  useEffect(() => {
    return function cleanup() {
      liveRegion.cleanup();
    };
  }, []);

  const contextValue = useMemo(() => {
    return {
      registerItem: registry?.register,
      reorderItem,
      instanceId,
      getListLength,
    };
  }, [registry?.register, reorderItem, instanceId, getListLength]);

  return (
    <ListContext.Provider value={contextValue}>{children}</ListContext.Provider>
  );
}

export function DNDListItem({
  item,
  index,
  elementRef,
  dragHandleRef,
  itemView,
  preview = null,
}) {
  const { registerItem, instanceId } = useListContext();

  const [closestEdge, setClosestEdge] = useState(null);
  const [draggableState, setDraggableState] = useState(idleState);

  useEffect(() => {
    const element = elementRef.current;
    const dragHandle = dragHandleRef.current;
    invariant(element);
    invariant(dragHandle);

    const data = getItemData({ item, index, instanceId });

    return combine(
      registerItem({ itemId: item?.uuid, element }),
      draggable({
        element: dragHandle,
        getInitialData: () => data,
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setDraggableState({ type: "preview", container });
              return () => setDraggableState(draggingState);
            },
          });
        },
        onDragStart() {
          setDraggableState(draggingState);
        },
        onDrop() {
          setDraggableState(idleState);
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return (
            isItemData(source.data) && source.data.instanceId === instanceId
          );
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self, source }) {
          const isSource = source.element === element;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;
          // invariant(typeof sourceIndex === "number");

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      })
    );
  }, [instanceId, item, index, registerItem, elementRef, dragHandleRef]);

  return (
    <Fragment>
      <div
        ref={elementRef}
        style={{
          position: "relative",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        {itemView}
        {closestEdge && (
          <DropIndicator
            edge={closestEdge}
            gap="1px"
            style={{ zIndex: 1500 }}
          />
        )}
      </div>
      {draggableState.type === "preview" &&
        preview &&
        ReactDOM.createPortal(preview, draggableState.container)}
    </Fragment>
  );
}
