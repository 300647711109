import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  ListItem,
  useTheme,
  Popover,
  Button,
  alpha,
  List,
} from "@mui/material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { useSnackbar } from "notistack";

import {
  updateBatchTransactions,
  cloneBatchTransactions,
} from "../../../../Helper/data";
import {
  setPopupStatus3,
  setPopupStatus4,
} from "../../../../store/slices/datasets";
import { setStageLoadingText } from "../../../../store/slices/appmain";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import RulesCommonView from "../../../Settings/Rules/RulesCommonView";
import useSubscriptions from "../../../../hooks/useSubscriptions";
import { Color, Constant, Fonts } from "../../../../Helper";
import EndPoints from "../../../../APICall/EndPoints";
import initialData from "./../../../../Helper/data";
import Icon from "../../../../components/Icon";
import APICall from "../../../../APICall";

const actionListOption = initialData?.actionListOption;

const TransactionViewSettingPopOver = ({
  selectedRowData,
  resetKanban,
  tab,
}) => {
  const theme = useTheme();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubscriptionValid] = useSubscriptions();
  const showInfo = useRef(false);
  const dispatch = useDispatch();
  const form_type = useRef(null);
  const itemRow = useRef({});
  const rulesRef = useRef(null);

  const disabled = selectedRowData?.length === 0;

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const isDevMode = useSelector((state) => state.commonSlice?.isDevMode);

  //states
  const [actionList, setActionList] = useState([]);

  //life cycles
  useDebounce(
    () => {
      if (selectedRowData?.length > 0) {
        let findRecurringRule = selectedRowData?.find(
          (o1) => o1.recurring_rule
        );
        let findBooked = selectedRowData?.find((o1) => o1.source === 2);
        let findBookedIgnored = selectedRowData?.find(
          (o1) => o1.source === 2 && o1.state === "Ignored"
        );
        let IDs = [];
        if (findRecurringRule) {
          showInfo.current =
            "bulk_action_disable_actions_info_reason_recurring_rule";
          IDs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
        } else if (findBookedIgnored) {
          showInfo.current = "bulk_action_disable_actions_info_reason_ignored";
          IDs = [1, 4, 6, 8, 9, 10, 11];
        } else if (findBooked) {
          showInfo.current = "bulk_action_disable_actions_info_reason_booked";
          IDs = [1, 4, 8, 9, 10, 11, 13];
        } else {
          showInfo.current = false;
          IDs = [13];
        }
        const inflow = selectedRowData?.filter((o1) => o1?.gross_value >= 0);
        const outflow = selectedRowData?.filter((o1) => o1?.gross_value < 0);

        if (inflow?.length > 0 && outflow?.length > 0) {
          IDs.push(3);
          showInfo.current =
            "bulk_action_disable_actions_info_reason_mixed_transactions";
        } else {
          showInfo.current = false;
        }

        if (tab !== "suggestionArray") {
          IDs.push(12);
        }
        if (isDevMode) {
          IDs = [];
        }
        setActionList(IDs);
      }
    },
    500,
    [selectedRowData, isDevMode],
    true
  );

  //functions
  const closeCategoryOverlay = () => {
    dispatch(setPopupStatus4(null));
  };

  const onClickCategoryTitle = (e, item) => {
    let array = [];
    selectedRowData.forEach((element, index) => {
      array.push({
        uuid: element.uuid,
        category: item.uuid,
        position: 1001 + index,
      });
    });
    updateCardsBatch(array);
    closeCategoryOverlay();
  };

  const onSaveForm = (obj) => {
    let item = {};
    if (form_type.current === "cost_unit") {
      item = {
        [form_type.current]: obj.uuid,
      };
    }
    if (form_type.current === "tax") {
      item = {
        [form_type.current]: obj?.uuid,
      };
    }
    if (form_type.current === "state" || form_type.current === "scenario") {
      item = {
        [form_type.current]: obj.title,
      };
    }

    if (form_type.current === "invoice_date") {
      item = {
        [form_type.current]: obj[form_type.current],
      };
    }
    if (form_type.current === "due_date") {
      item = {
        [form_type.current]: obj?.[form_type.current],
      };
      if (obj?.[form_type.current]) {
        item = {
          invoice_date: null,
        };
      }
    }
    updateBatchValue(item);
    closeFormOverlay();
    closeCategoryOverlay();
  };

  const closeFormOverlay = () => {
    form_type.current = null;
    dispatch(setPopupStatus4(null));
  };

  const updateBatchValue = (item) => {
    let array = [];
    selectedRowData.forEach((element, index) => {
      array.push({
        uuid: element.uuid,
        ...item,
      });
    });
    let resetObj = null;
    if (item?.hasOwnProperty("state")) {
      resetObj = {
        refetchBalance: true,
      };
    }
    updateCardsBatch(array, resetObj);
    closeCategoryOverlay();
  };

  const handleClickSettings = (e, uuid) => {
    if (uuid === 1) {
      dispatch(
        setPopupStatus3({
          id: "simple-popper",
          open: true,
          overlay_type: "delete",
          onConfirm: onDelete,
          payload: {
            title: t("Attention"),
            message: t(
              "Are you sure that you want to delete this transaction?"
            ),
            confirmText: t("Ok"),
            hideCancel: true,
          },
        })
      );
    }
    if (uuid === 2) {
      if (isSubscriptionValid({ showMessage: true })) {
        let array = [];
        selectedRowData.forEach((item) => {
          array.push({
            uuid: item.uuid,
            gross_value: item.gross_value,
            scenario: item.scenario,
            state: initialData?.bookedState.includes(item.state)
              ? "Open"
              : item.state,
            category: item?.category,
            due_date: item.due_date,
            cost_unit: item.cost_unit,
            contact: item.contact,
            tax: item.tax,
            source: 1,
            data_source:
              item.source === 1
                ? item.data_source
                : dataSetData?.internal_data_source,
          });
        });
        cloneBatch(array);
      }
    }
    if (uuid === 3) {
      let numbers = selectedRowData.map((item) => item.gross_value);
      const areAllPositive = numbers.every((number) => Math.sign(number) === 1);
      const areAllNegative = numbers.every(
        (number) => Math.sign(number) === -1
      );
      let type = 3;
      if (areAllPositive) {
        type = 1;
      }
      if (areAllNegative) {
        type = 2;
      }
      dispatch(
        setPopupStatus4({
          open: true,
          anchorEl: e.currentTarget,
          overlay_type: "category",
          payload: {
            type: type,
            onClickCategoryTitle: onClickCategoryTitle,
          },
        })
      );
    }
    if (uuid === 5) {
      let array = [];
      selectedRowData.forEach((item) => {
        array.push({
          uuid: item.uuid,
          category: null,
        });
      });
      updateCardsBatch(array);
    }
    if (uuid === 6) {
      updateBatchValue({ state: "Ignored" });
    }
    if (uuid === 13) {
      updateBatchValue({ state: "Booked" });
    }
    if (
      uuid === 4 ||
      uuid === 7 ||
      uuid === 8 ||
      uuid === 9 ||
      uuid === 10 ||
      uuid === 14
    ) {
      form_type.current = "state";

      if (uuid === 7) {
        form_type.current = "cost_unit";
      }
      if (uuid === 8) {
        form_type.current = "scenario";
      }
      if (uuid === 9) {
        form_type.current = "due_date";
      }
      if (uuid === 10) {
        form_type.current = "invoice_date";
      }
      if (uuid === 14) {
        form_type.current = "tax";
      }
      dispatch(
        setPopupStatus4({
          open: true,
          anchorEl: true,
          overlay_type: "form",
          payload: {
            type: form_type.current,
            onSave: onSaveForm,
          },
        })
      );
    }
    if (uuid === 12) {
      let array = [];
      selectedRowData.forEach((element) => {
        array.push({
          uuid: element?.uuid,
          category: element?.suggested_category,
        });
      });
      updateCardsBatch(array);
    }
  };

  const onDelete = () => {
    let uuids = selectedRowData?.map((o1) => o1.uuid);
    deleteBatch(uuids);
  };

  //apis
  const deleteBatch = async (uuids) => {
    dispatch(setStageLoadingText("common_process_loader_text"));
    await APICall("post", EndPoints.transactions + "batch_delete/", uuids).then(
      (response) => {
        dispatch(setPopupStatus3(null));
        dispatch(setStageLoadingText(null));
        if (response.status === 204) {
          if (resetKanban) resetKanban();
          enqueueSnackbar(t("Cards Deleted Successfully"));
        }
      }
    );
  };

  const cloneBatch = async (array) => {
    dispatch(setStageLoadingText("common_process_loader_text"));
    let response = await cloneBatchTransactions(array);
    if (response) {
      enqueueSnackbar(t("Cards Cloned Successfully"));
      if (resetKanban) resetKanban();
    }
    dispatch(setStageLoadingText(null));
  };

  const updateCardsBatch = async (array, resetObj) => {
    itemRow.current.category = array?.[0]?.category;
    dispatch(setStageLoadingText("common_process_loader_text"));
    const response = await updateBatchTransactions(array);
    const rulesError = response?.find((o1) =>
      o1?.category?.[0]?.includes("Cannot set category.")
    );
    if (rulesError) {
      dispatch(setStageLoadingText(null));
      rulesRef.current?.onOpen({
        payload: array,
        response,
        message: response?.[0].category?.[0],
        transaction_rule: rulesError?.transaction_rule?.[0],
        rule_type: rulesError?.type?.[0],
        title: itemRow.current?.title,
        transaction_type: itemRow.current?.transaction_type,
        category: itemRow.current?.category,
      });
    } else {
      if (response) {
        resetKanban(resetObj);
        dispatch(setStageLoadingText(null));
      }
    }
  };

  return (
    <>
      <RulesCommonView ref={rulesRef} />
      <PopupState
        variant="popper"
        id="demo-mutiple-checkbox"
        popupId="demo-popup-popover"
      >
        {(popupState) => (
          <>
            <Button
              startIcon={
                <Icon
                  icon={<HiOutlineLightningBolt />}
                  fontSize={"1.4rem"}
                  color={
                    disabled ? theme.palette.color.slate[400] : Color.white
                  }
                ></Icon>
              }
              {...bindTrigger(popupState)}
              sx={{
                height: "1.875rem",
                minWidth: 0,
                p: "1.25rem",
                mr: "1rem",
                color: disabled ? theme.palette.color.slate[300] : Color.white,
                backgroundColor: disabled
                  ? theme.palette.color.slate[100]
                  : theme.palette.primary.dark,
                borderRadius: 6,
                fontWeight: 500,
                textTransform: "initial",
                fontSize: "1rem",
                "&:hover": {
                  backgroundColor: theme.palette.primary.darkHover,
                },
              }}
            >
              {t("Run bulk action")}
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              slotProps={{
                paper: {
                  sx: {
                    borderRadius: 1.5,
                    boxShadow: Constant.OverlayBoxShadow,
                  },
                },
              }}
              sx={{
                ml: "0.5rem",
              }}
            >
              <List
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "fit-content",
                  minHeight: "3.125rem",
                  width: "fit-content",
                  minWidth: "18.75rem",
                  position: "relative",
                }}
              >
                {showInfo.current ? (
                  <Typography
                    variant="caption"
                    fontWeight={"fontWeightMediumBold"}
                    sx={{
                      color: Color.tailwind.slate[500],
                      mx: "1.25rem",
                      mb: `0.5rem`,
                    }}
                  >
                    {t(showInfo.current)}
                  </Typography>
                ) : null}
                {actionListOption?.map((item) => {
                  const disabled = actionList?.includes(item?.uuid);
                  return (
                    <ListItem
                      key={`key-${item?.uuid}`}
                      disableGutters
                      disablePadding
                      onClick={
                        disabled
                          ? undefined
                          : (e) => handleClickSettings(e, item?.uuid)
                      }
                    >
                      <ListItemButton
                        disabled={disabled}
                        sx={{
                          fontFamily: Fonts?.Text,
                          "&:hover": {
                            backgroundColor: alpha(Color.themeColor, 0.1),
                            color: alpha(Color.themeColor, 0.9),
                            "& span": {
                              fontWeight: 500,
                              color: alpha(Color.themeColor, 0.9),
                            },
                            "& svg": {
                              color: alpha(Color.themeColor, 0.9),
                            },
                          },
                        }}
                      >
                        <ListItemIcon>{item?.icon}</ListItemIcon>
                        <ListItemText primary={t(item?.title)} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
};

export default TransactionViewSettingPopOver;
