import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  ListItem,
  MenuItem,
  useTheme,
  Popover,
  List,
  Menu,
  Box,
} from "@mui/material";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

import { cloneDeep, getTailwindColor } from "../../../../../Helper/data";
import { setPopupStatus4 } from "../../../../../store/slices/datasets";
import TitleInput from "../../../../../components/Overlay/TitleInput";
import CustomModal from "../../../../../components/Model/CustomModal";
import { setReportFilter } from "../../../../../store/slices/report";
import useSubscriptions from "../../../../../hooks/useSubscriptions";
import RadioCircle from "../../../../../components/RadioCircle";
import useStatusHook from "../../../../../hooks/useStatusHook";
import AddButton from "../../../../../components/AddButton";
import EndPoints from "../../../../../APICall/EndPoints";
import { queryClient } from "../../../../../App";
import { Constant } from "../../../../../Helper";
import CommonFunctions from "./CommonFunctions";
import APICall from "../../../../../APICall";

const options = [
  {
    value: 1,
    label: "Edit",
    icon: <EditIcon />,
  },
  {
    value: 2,
    label: "Delete",
    icon: <DeleteIcon />,
  },
];

const ReportsPagesOverlay = ({
  anchorEl = null,
  handleClosePopOver,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "right",
  },
}) => {
  const editItem = useRef();
  const commonRef = useRef(null);
  const dispatch = useDispatch();
  const ReportPages = useStatusHook("ReportPages");
  const theme = useTheme();

  //redux
  const Page = useSelector((state) => state.reportSlice.Page);
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const pagesList = ReportPages?.data;

  //state
  const [anchorElInner, setAnchorElInner] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  //api
  const deleteReportPageByIdApi = async (id) => {
    await APICall("delete", EndPoints.reporting_dashboards + `${id}/`).then(
      (response) => {
        if (response.status === 204 && response) {
          if (Page?.uuid === id) {
            dispatch(
              setReportFilter({
                key: "Page",
                value: null,
              })
            );
          }
          resetReportPages();
        }
      }
    );
  };

  const batchUpdatePage = async (payload) => {
    await APICall(
      "put",
      EndPoints.reporting_dashboards + `batch_update/`,
      payload
    ).then((response) => {
      if (response.status === 200 && response.data) {
        resetReportPages();
      }
    });
  };

  //functions
  const resetReportPages = () => {
    setTimeout(() => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === "ReportPages" &&
          query.queryKey[1]?.dataset === dataset,
      });
    }, 0);
  };

  const onClose = () => {
    dispatch(setPopupStatus4(null));
    if (handleClosePopOver) {
      handleClosePopOver();
    }
  };

  const handleToggle = (_page) => {
    let _widgets = [..._page?.widgets]?.sort((a, b) => a.position - b.position);
    dispatch(
      setReportFilter({
        key: "Page",
        value: _page,
      })
    );
    commonRef.current.updatePageLayout({
      widgets: cloneDeep(_widgets),
    });
    global.reportManualUpdate = true;
  };

  const onClickEdit = (e) => {
    setAnchorElInner(e.currentTarget);
  };

  const handleCloseInner = () => {
    setAnchorElInner(null);
  };

  const handleClick = (event, item) => {
    setAnchorElMenu(event.currentTarget);
    editItem.current = item;
  };

  const handleClose = () => {
    setAnchorElMenu(null);
  };

  const onClickItem = (e, value) => {
    if (value === 1) {
      onClickEdit(e);
    }
    if (value === 2) {
      setAnchorElMenu(null);
      onClickDelete();
    }
  };

  const onClickDelete = async () => {
    deleteReportPageByIdApi(editItem.current.uuid);
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const keys = ["ReportPages", { dataset }];
    let updatedArray = [];
    queryClient.setQueryData(keys, (oldData) => {
      let [changedData] = oldData.splice(result.source.index, 1);
      oldData.splice(result.destination.index, 0, changedData);
      updatedArray = oldData;
      return oldData;
    });
    let batchArray = [];
    updatedArray?.forEach((item, index) => {
      batchArray.push({
        uuid: item?.uuid,
        title: item?.title,
        dataset: item?.dataset,
        position: index,
      });
    });
    batchUpdatePage(batchArray);
  };

  return (
    <Popover
      id={Boolean(anchorEl) ? "simple-popover-1" : undefined}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      elevation={2}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      slotProps={{
        paper: {
          sx: {
            border: 0,
            borderRadius: theme.borderRadius.main,
            boxShadow: Constant.OverlayBoxShadow,
            mt: "0.5rem",
          },
        },
      }}
    >
      <Box
        sx={{
          width: "fit-content",
          maxHeight: "60rem",
          height: `${pagesList?.length * 3.6 + 8}rem`,
          minWidth: "35rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: 0,
          borderRadius: 4,
          position: "relative",
        }}
      >
        <CommonFunctions ref={commonRef} />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable-1" type="SHEET">
            {(provided) => (
              <List
                sx={{ width: "100%" }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {pagesList?.map((item, index) => {
                  return (
                    <Draggable
                      key={item?.uuid}
                      draggableId={`droppable-${item?.uuid}`}
                      index={index}
                    >
                      {(provided) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          secondaryAction={
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-haspopup="true"
                              onClick={(e) => handleClick(e, item)}
                              sx={{ background: "transparent !important" }}
                            >
                              <MoreVertIcon
                                sx={{
                                  background: "transparent !important",
                                  color: theme.palette.color.slate[400],
                                }}
                              />
                            </IconButton>
                          }
                          disablePadding
                          sx={{
                            pl: "1rem",
                            height: "3rem",
                            "&.MuiListItem-root": {
                              backgroundColor:
                                Page?.uuid === item?.uuid
                                  ? getTailwindColor("slate", 50)
                                  : "transparent",
                              "&:hover": {
                                backgroundColor: `${getTailwindColor(
                                  "slate",
                                  50
                                )} !important`,
                                "& #dataset-drag-handle": {
                                  display: "flex",
                                },
                              },
                            },
                            "& .MuiButtonBase-root": {
                              backgroundColor: "transparent !important",
                            },
                            "& .MuiListItemSecondaryAction-root": {
                              backgroundColor: "transparent !important",
                            },
                          }}
                        >
                          <ListItemButton
                            disableRipple
                            role={undefined}
                            onClick={() => handleToggle(item)}
                            sx={{
                              py: 0,
                              px: "1rem",
                            }}
                          >
                            <Box
                              id={"dataset-drag-handle"}
                              {...provided.dragHandleProps}
                              sx={{
                                display: "none",
                                alignItems: "center",
                                position: "absolute",
                                left: "-1.5%",
                              }}
                            >
                              <DragIndicatorOutlinedIcon />
                            </Box>
                            <ListItemIcon>
                              <RadioCircle
                                checked={Page?.uuid === item?.uuid}
                                size={22}
                                color={getTailwindColor("slate", 500)}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={item?.title}
                              sx={{
                                "& .MuiListItemText-primary": {
                                  fontWeight:
                                    Page?.uuid === item?.uuid ? 700 : 600,
                                  fontSize: "0.85rem",
                                  color: getTailwindColor("slate", 500),
                                },
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      )}
                    </Draggable>
                  );
                })}
              </List>
            )}
          </Droppable>
        </DragDropContext>

        <AddButtonView />
      </Box>
      {Boolean(anchorElMenu) ? (
        <MenuView
          anchorEl={anchorElMenu}
          open={Boolean(anchorElMenu)}
          options={options}
          handleClose={handleClose}
          onClickItem={onClickItem}
        />
      ) : null}
      {Boolean(anchorElInner) ? (
        <InnerOverlay
          open={Boolean(anchorElInner)}
          anchorEl={anchorElInner}
          onClose={handleCloseInner}
          modalType={"edit"}
          editItem={editItem.current}
        />
      ) : null}
    </Popover>
  );
};

export default ReportsPagesOverlay;

const AddButtonView = () => {
  const { t } = useTranslation();

  //state
  const [anchorElInner, setAnchorElInner] = useState(null);

  //functions
  const onClickAdd = (e) => {
    setAnchorElInner(e.currentTarget);
  };

  const handleCloseInner = () => {
    setAnchorElInner(null);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        position: "absolute",
        bottom: "1.5rem",
        left: "1.5rem",
      }}
    >
      <InnerOverlay
        open={Boolean(anchorElInner)}
        anchorEl={anchorElInner}
        onClose={handleCloseInner}
      />
      <AddButton
        onClick={onClickAdd}
        isSecondary
        hideKey
        label={t("Add Page")}
        sx={{
          width: "fit-content",
          mt: "3rem",
        }}
      />
    </div>
  );
};

const MenuView = ({ anchorEl, open, handleClose, onClickItem, options }) => {
  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: 48 * 4.5,
          width: "20ch",
        },
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option?.value}
          onClick={(e) => onClickItem(e, option?.value)}
        >
          <ListItemIcon>{option?.icon}</ListItemIcon>
          <Typography variant="inherit" noWrap>
            {option?.label}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

const InnerOverlay = ({
  onClose,
  open,
  anchorEl,
  modalType = "add",
  editItem,
}) => {
  const { t } = useTranslation();
  const [isSubscriptionValid] = useSubscriptions();
  let isDataUpdated = useRef(false);

  //redux
  const pagesList = useSelector((state) => state.reportSlice.pagesList);
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  //state
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({
    title: "",
  });
  const [error, setError] = useState({
    title: "",
  });

  const createNewPage = async (obj) => {
    setLoading(true);
    await APICall("post", EndPoints.reporting_dashboards, obj)
      .then((response) => {
        if (response.status === 201 && response.data) {
          onClose();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePageByID = async (id, obj) => {
    setLoading(true);
    await APICall("patch", EndPoints.reporting_dashboards + `${id}/`, obj)
      .then((response) => {
        if (response.status === 200 && response.data) {
          onClose();
          resetReportPages();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addReportPageMutation = useMutation({
    mutationFn: (obj) => createNewPage(obj),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["ReportPages", { dataset: dataset }],
      });
    },
  });

  useEffect(() => {
    if (Boolean(anchorEl)) {
      isDataUpdated.current = false;
      if (modalType === "add") {
        setItem({
          title: "",
        });
      } else {
        setItem(editItem);
      }
    }
  }, [editItem, anchorEl, modalType]);

  //functions
  const resetReportPages = () => {
    setTimeout(() => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === "ReportPages" &&
          query.queryKey[1]?.dataset === dataset,
      });
    }, 0);
  };

  const onChange = (event) => {
    event?.preventDefault();
    isDataUpdated.current = true;
    setError({});
    setItem((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onConfirm = () => {
    if (modalType === "add") {
      if (isSubscriptionValid({ showMessage: true })) {
        let errorText = "";
        if (item?.title?.trim("") === "") {
          errorText = t("Dashboard Title is required");
        }
        if (errorText) {
          setError((prev) => ({ ...prev, title: errorText }));
          return;
        }

        const maxPosition = pagesList?.reduce(
          (max, { position }) => Math.max(max, position),
          0
        );
        const obj = {
          title: item?.title,
          note: "note",
          position: (Number(maxPosition) || 0) + 1,
          dataset: dataset,
        };
        addReportPageMutation.mutate(obj);
      }
    } else {
      if (isDataUpdated.current) {
        updatePageByID(editItem?.uuid, {
          title: item?.title,
        });
      }
    }
  };

  return (
    <CustomModal
      textAdd={modalType === "add" ? t("Create") : t("save")}
      heading={t("Create Page")}
      onClose={onClose}
      onAdd={onConfirm}
      loadingAdd={loading}
      modalType={modalType}
      open={open}
    >
      <Box
        sx={{
          p: "2rem",
          width: "fit-content",
          maxHeight: "50rem",
          minWidth: "45rem",
          height: "max-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: 0,
          borderRadius: 4,
          position: "relative",
        }}
      >
        <TitleInput
          value={item?.title}
          onChange={onChange}
          name="title"
          label={t("Title")}
          placeholder={t("page_title_placeholder")}
          hideTitle
          likeGoogle
          variant="filled"
          disabled={loading}
          size="small"
          error={error?.title}
          helperText={error?.title}
          sx={{
            width: "100%",
          }}
        />
      </Box>
    </CustomModal>
  );
};
